
.authorizeInput {
  border: #3BAAE1 1px solid;
  border-radius: 2px;
  color: #4a4a4a;
  font-size: 2.5rem;
  font-weight: 600;
  height: 64px;
  margin: auto .25rem;
  text-align: center;
  width: 96px;
}